import React from "react";
import styled from "styled-components";

import { Section } from "../Section";
import { theme } from "../../components/theme";

import i18next from "i18next";

const HowItWorks = () => {
  const data = {
    fr: [
      {
        title:
          "Chaque semaine ou deux, nous créons votre panier selon vos préférences",
        titleSmall: "",
        lineBreak: true,
      },
      {
        title: "Personnalisez-le en ajoutant ou retirant des produits",
        titleSmall: "",
        lineBreak: true,
      },
      {
        title:
          "Récupérez votre panier dans un point de collecte ou faites-le livrer a votre porte",
      },
      {
        title:
          "❌ Pas besoin cette semaine ? Repoussez à la prochaine livraison",
      },
    ],
    en: [
      {
        title:
          "Every week or two, we create your basket based on your preferences.",
        titleSmall: "",
        lineBreak: false,
      },
      {
        title: "Personalize it by adding or removing products.",
        titleSmall: "",
        lineBreak: false,
      },
      {
        title:
          "Pick up your basket at a collection point or have it delivered to your door.",
        lineBreak: false,
      },
      {
        title:
          "❌ Don’t need it this week? Simply push it to the next delivery.",
        lineBreak: false,
      },
    ],
  };

  const lang: string = i18next.language;
  let localeData;
  if (lang === "fr" || lang === "fr-CA") {
    localeData = data.fr;
  } else {
    localeData = data.en;
  }

  return (
    <SectionStyled>
      <LeftStyled>
        {localeData.map((el, i: number) => (
          <StepContainer key={i}>
            <StepNumberStyled>{i + 1}</StepNumberStyled>
            <TitleStyled>
              {el.title}
              {el.lineBreak && <br />}
              {el.titleSmall && (
                <TitleSmallStyled>{el.titleSmall}</TitleSmallStyled>
              )}
            </TitleStyled>
          </StepContainer>
        ))}
      </LeftStyled>
      <ImageStyled src="/images/howitworks.png" />
    </SectionStyled>
  );
};

const SectionStyled = styled(Section)`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  gap: 2rem;

  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    padding-top: 0;
  }
`;

const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: ${theme.breakpoints.md}) {
    max-width: 700px;
    padding: 2rem;
  }
`;

const ImageStyled = styled.img`
  object-fit: contain;
  margin: auto;

  @media (max-width: ${theme.breakpoints.md}) {
    max-width: 500px;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 300px;
  }
`;

const TitleStyled = styled.h4`
  font-size: 24px;
  color: ${theme.colors.base.primary};
  color: ${theme.font.family.primary};

  @media (max-width: ${theme.breakpoints.md}) {
    font-size: 18px;
  }
`;

const TitleSmallStyled = styled.span`
  color: ${theme.colors.base.primary};
  font-size: 14px;
  font-weight: 500;

  @media (max-width: ${theme.breakpoints.md}) {
    font-size: 13px;
  }
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
`;

const StepNumberStyled = styled.div`
  background-color: white;
  width: 60px;
  height: 60px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1e7d7;
  color: ${theme.colors.base.secondary};
  font-family: "Recoleta";
  font-weight: 700;
  font-size: 35px;
  border-radius: 1000px;
`;

export default HowItWorks;
