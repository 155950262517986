import React from "react";
import styled from "styled-components";

import { Heading } from "../Heading";
import { Section } from "../Section";
import { Text } from "../Text";
import { theme } from "../theme";
import { useAppState } from "../../contexts";
import { useFaqByTopic } from "../../hooks/Faq";
import ReactHtmlParser from "react-html-parser";
import { TFaqCopies, TTopicEnum } from "../../types/Faq";
import useComponentVisible from "../../hooks/useComponentVisible";

interface Props {
  topic: TTopicEnum;
}

export const HelpList: React.FC<Props> = ({ topic }) => {
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const { data: faq } = useFaqByTopic(topic);
  return (
    <SectionMain>
      {faq &&
        faq.map((element, index) => (
          <div key={index}>
            <SectionStyled>
              <Accordion
                question={element.question}
                answer={element.anwser}
                lang={lang}
              >
                {element.anwser}
              </Accordion>
            </SectionStyled>
          </div>
        ))}
    </SectionMain>
  );
};

const TextStyled = styled(Text)`
  padding-top: ${theme.spacing[1]};
`;

interface Props1 {
  question: TFaqCopies;
  lang: string;
  answer: TFaqCopies;
}

const Accordion: React.FC<Props1> = ({ question, children, answer, lang }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <ArticleStyled ref={ref}>
      {question && (
        <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
          <HeadingStyled>{ReactHtmlParser(question[lang]?.text)}</HeadingStyled>
        </div>
      )}
      {isComponentVisible && answer && (
        <TextStyled>{ReactHtmlParser(answer[lang]?.text)}</TextStyled>
      )}
    </ArticleStyled>
  );
};

const ArticleStyled = styled.article`
  padding: ${theme.spacing[1]};
  background-color: ${theme.colors.accent.secondary};
  border-radius: ${theme.border.radius.sm};
`;
const SectionStyled = styled(Section)`
  padding-top: 0;
  padding-bottom: ${theme.spacing[0.5]};
`;
const HeadingStyled = styled(Heading)`
  margin-bottom: 0;
`;

const SectionMain = styled(Section)`
  padding-top: ${theme.spacing[2]};
`;
