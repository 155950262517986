import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { useAppState } from "../../../contexts";
import {
  useCreateLADDelivery,
  useTimeslotsByZipcode,
} from "../../../hooks/Delivery";
import { TDeliveryProfile } from "../../../types/Delivery";
import { TFrequenceEnum } from "../../../types/NextDeliveriesFrequencies";
import { TSubscriptionComplete } from "../../../types/Subscription";
import { TTimeSlot } from "../../../types/TimeSlot";
import { formatTimeslotDay, formatTimeslotHours } from "../../../utils/Date";
import { Button } from "../../Button";
import { ChoiceList } from "../../ChoiceList";
import { TChoice } from "../../ChoiceList/ChoiceList";
import { Form } from "../../Form";
import { FormControl } from "../../FormControl";
import { FormInput } from "../../FormInput";
import { FormInputControl } from "../../FormInputControl";
import { FormLabel } from "../../FormLabel";
import { FormSelect } from "../../FormSelect";
import { Heading } from "../../Heading";
import { Loading } from "../../Loading";
import { Snackbar } from "../../Snackbar";
import { theme } from "../../theme";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { useHistory } from "react-router-dom";

interface Props {
  isSignup: boolean;
  onCancelChanges?: () => void;
  onDone: () => void;
  setInputs: (value: any) => void;
  inputs: any;
}

export const AddressFormNotSubscribe: React.FC<Props> = ({
  isSignup,
  onCancelChanges,
  setInputs,
  inputs,
  onDone,
}) => {
  const { t } = useTranslation();
  const methods = useForm();
  const { errors } = methods;
  const { locale } = useAppState();
  const { mutate, isSuccess, isLoading } = useMutation(useCreateLADDelivery);
  const history = useHistory();

  const [timeslots, setTimeslots] = useState<TTimeSlot[]>([]);
  const [timeslotSelected, setTimeslotSelected] = useState<string>();
  // const [inputs, setInputs] = useState<
  //   Pick<
  //     TDeliveryProfile,
  //     | "address"
  //     | "address2"
  //     | "city"
  //     | "province"
  //     | "zip_code"
  //     | "country"
  //     | "note"
  //   >
  // >({
  //   address: "",
  //   address2: "",
  //   city: "",
  //   province: "QC",
  //   zip_code: "",
  //   country: "CA",
  //   note: "",
  // });

  // const { data: timeslotsFound } = useTimeslotsByZipcode(
  //   inputs?.zip_code,
  //   inputs?.province
  // );

  useEffect(() => {
    isSuccess && onDone();
  }, [isSuccess]);

  // const resetForm = () => {
  //   if (
  //     subscription &&
  //     subscription.delivery &&
  //     subscription.delivery.entity_type === "LAD"
  //   ) {
  //     const newAddress = {
  //       address: subscription.delivery.address,
  //       address2: subscription.delivery.address2,
  //       city: subscription.delivery.city,
  //       province: subscription.delivery.province,
  //       zip_code: subscription.delivery.zip_code,
  //       country: subscription.delivery.country,
  //       note: subscription.delivery.note,
  //     };

  //     setInputs({
  //       ...inputs,
  //       ...newAddress,
  //     });
  //   }
  // };
  const redirectTo = (path: string) => () => history.push(path);

  const onFormSubmitHandler = () => {
    // if (timeslotSelected && frequency && inputs) {
    //   mutate({
    //     timeslotId: timeslotSelected,
    //     frequence: frequency,
    //     address: { ...inputs },
    //   });
    // }
  };

  const onCancelHandler = () => {
    // resetForm();
    // onCancelChanges && onCancelChanges();
  };

  const onSlotSubmitHandler = (timeslotId: string) => {
    setTimeslotSelected(timeslotId);
  };

  const setAddress = async (placeTerms: any) => {
    if (!placeTerms) return;

    const newAddress = {
      streetNumber: "",
      address: "",
      city: "",
      province: "",
      zip_code: "",
      country: "",
      latlng: { lat: "", lng: "" },
    };

    placeTerms.forEach((term: any) => {
      if (term.types.includes("street_number"))
        newAddress["streetNumber"] = term.long_name;
      if (term.types.includes("route")) newAddress["address"] = term.long_name;
      if (term.types.includes("locality")) newAddress["city"] = term.long_name;
      if (term.types.includes("administrative_area_level_1"))
        newAddress["province"] = term.short_name;
      if (term.types.includes("postal_code"))
        newAddress["zip_code"] = term.long_name;
      if (term.types.includes("country"))
        newAddress["country"] = term.short_name;
      if (term.types.includes("geolocation")) {
        newAddress["latlng"].lat = term.lat;
        newAddress["latlng"].lng = term.lng;
      }
    });

    let addressfinal = `${newAddress.streetNumber} ${newAddress.address}`;
    if (addressfinal === " ") {
      addressfinal = "";
    }

    setInputs({
      ...inputs,
      ...newAddress,
      address: addressfinal,
    });
  };

  return (
    <>
      <Form useForm={methods} onSubmit={onFormSubmitHandler}>
        <InnerFormWrapper>
          <Heading size="sm">
            {t("Signup.step3.subheading.findYourAddress")}
          </Heading>

          <AddressAutocomplete
            setLatLng={(latLng) => {}}
            setValue={setAddress}
            locale={locale}
            region="ca"
          />

          {inputs && (
            <InputsWrapper>
              <FormControl columns={2}>
                <FormInputControl
                  errors={
                    errors.address && [
                      t("Signup.step3.address.errors.required"),
                    ]
                  }
                >
                  <FormLabel target="address">
                    {t("Signup.step3.form-address")}
                  </FormLabel>
                  <FormInput
                    id="address"
                    type="text"
                    name="address"
                    disabled={true}
                    value={inputs.address || ""}
                    error={errors.address}
                    register={methods.register}
                    validation={{
                      required: true,
                      minLength: 2,
                    }}
                  />
                </FormInputControl>
                <FormInputControl>
                  <FormLabel target="address2">
                    {t("Signup.step3.form-address2")}
                  </FormLabel>
                  <FormInput
                    id="address2"
                    type="text"
                    name="address2"
                    value={inputs.address2 || ""}
                    error={errors.address2}
                    onChange={(value) =>
                      setInputs({ ...inputs, address2: value })
                    }
                    register={methods.register}
                    validation={{}}
                  />
                </FormInputControl>
              </FormControl>

              <FormControl columns={2}>
                <FormControl columns={2}>
                  <FormInputControl
                    errors={
                      errors.city && [t("Signup.step3.city.errors.required")]
                    }
                  >
                    <FormLabel target="city">
                      {t("Signup.step3.form-city")}
                    </FormLabel>
                    <FormInput
                      id="city"
                      type="text"
                      name="city"
                      disabled={true}
                      value={inputs.city}
                      error={errors.city}
                      register={methods.register}
                      validation={{
                        required: true,
                        minLength: 2,
                      }}
                    />
                  </FormInputControl>
                </FormControl>
                <FormControl columns={2}>
                  <FormInputControl
                    widthRatio={2}
                    errors={
                      errors.zip_code && [
                        t("Signup.step3.zip_code.errors.required"),
                      ]
                    }
                  >
                    <FormLabel target="zip_code">
                      {t("Signup.step3.form-zipcode")}
                    </FormLabel>
                    <FormInput
                      id="zip_code"
                      type="text"
                      name="zip_code"
                      disabled={true}
                      value={inputs.zip_code || ""}
                      error={errors.zip_code}
                      register={methods.register}
                      validation={{
                        required: true,
                      }}
                    />
                  </FormInputControl>
                </FormControl>
              </FormControl>

              <FormControl columns={2}>
                <FormInputControl
                  errors={
                    errors.province && [
                      t("Signup.step3.province.errors.required"),
                    ]
                  }
                >
                  <FormLabel target="province">
                    {t("Signup.step3.form-province")}
                  </FormLabel>
                  <FormSelect
                    id="province"
                    name="province"
                    value={inputs.province || ""}
                    options={[{ label: "Quebec", value: "QC" }]}
                    error={errors.province}
                    register={methods.register}
                    validation={{
                      required: true,
                      minLength: 2,
                    }}
                  />
                </FormInputControl>
                <FormControl columns={2}>
                  <FormInputControl>
                    <FormLabel target="note">
                      {t("Signup.step3.form-note")}
                    </FormLabel>
                    <FormInput
                      id="note"
                      type="text"
                      name="note"
                      value={inputs.note || ""}
                      error={errors.note}
                      onChange={(value) =>
                        setInputs({ ...inputs, note: value })
                      }
                      register={methods.register}
                      validation={{}}
                    />
                  </FormInputControl>
                </FormControl>
              </FormControl>
            </InputsWrapper>
          )}
        </InnerFormWrapper>
      </Form>
      {isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {isSuccess && (
        <Snackbar type={"success"} text={t("Snackbar.changed.delivery")} />
      )}
      {/* {timeslotsFound?.length === 0 && (
        <Snackbar type="error" text={t("Signup.step3.not-available")} />
      )} */}
    </>
  );
};

interface TimeSlotSelectorProps {
  timeslotSelected?: string;
  timeslots: TTimeSlot[];
  onSelect: (timeslotId: string) => void;
}

export const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({
  timeslotSelected,
  timeslots,
  onSelect,
}) => {
  const { locale } = useAppState();
  const [slots, setSlots] = useState<TChoice[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSlots(
      timeslots.map((t) => {
        return {
          day: `${formatTimeslotDay(t?.day, locale, "short")}`,
          time: `${formatTimeslotHours(
            t?.day,
            t?.start_time,
            t?.end_time,
            locale,
            "short"
          )}`,
          value: `${t.id}`,
        };
      })
    );
  }, [locale, timeslots]);

  const onSelectSlot = (choice: TChoice) => onSelect(choice.value);

  const selectedChoice = slots.find((slot) => slot.value === timeslotSelected);
  return (
    <FormControl>
      {slots.length > 0 && (
        <>
          <Heading size="sm">{t("Signup.step3.form-timeslot")}</Heading>
          <ChoiceList
            selectedChoice={selectedChoice}
            shouldReset={false}
            choices={slots}
            onSelect={onSelectSlot}
          />
        </>
      )}
    </FormControl>
  );
};

const InnerFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1.5]};
  background-color: ${theme.colors.accent.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[2]} ${theme.spacing[5]} ${theme.spacing[3]}
      ${theme.spacing[5]};
  }
`;

const InputsWrapper = styled.div`
  margin-top: ${theme.spacing[1]};
`;

const ButtonWrapper = styled.div`
  margin-top: ${theme.spacing[1]};
`;

const LoadingWrapper = styled.div`
  > div {
    position: fixed;
  }
`;
const BottomSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${theme.spacing[1]};

  > button {
    width: 200px;
    margin-left: ${theme.spacing[0.5]};
    margin-right: ${theme.spacing[0.5]};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[1]};
  }
`;
