import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Delivery } from "../../../components/Delivery";
import { AlternateSection } from "../../../components/AlternateSection";
import { Page } from "../../../components/Page";
import { theme } from "../../../components/theme";
import { WithAuth } from "../../../components/WithAuth";
import { useSubscription } from "../../../hooks/Subscriptions";
import { SignUpProgress } from "../SignUpProgress";
import { useAppDispatch } from "../../../contexts";
import { pushEvent } from "../../../gtm";
import { EVENTS } from "../../../data/events";

export const Step3: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: subscription, refetch } = useSubscription();
  const token = localStorage.getItem("token");
  const appDispatch = useAppDispatch();
  useEffect(() => {
    if (!token) {
      history.push("/sign-up/step-1");
    }
  }, []);

  useEffect(() => {
    if (subscription?.plans) {
      appDispatch({
        type: "SET_PLANS",
        payload: {
          plans: subscription.plans,
        },
      });
      return;
    }
  }, [subscription]);

  useEffect(() => {
    if (subscription?.status === "active") {
      history.push("/dashboard");
    }
  }, [subscription]);

  const onNext = () => {
    refetch().then(({ data }) => {
      if (data?.status === "active") {
        history.push("/dashboard");
      } else {
        pushEvent(EVENTS.SIGNUP_COMPLETE_DELIVERY_FORM);
        history.push("/sign-up/confirm");
      }
    });
  };
  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        <AlternateSectionStyled>
          <HeaderWrapperStyled>
            <SignUpProgress current={4} title={t("Signup.heading.step4")} />
          </HeaderWrapperStyled>
          <SectionStyled>
            <Delivery
              isSignup={true}
              subscription={subscription}
              onDone={onNext}
            />
          </SectionStyled>
        </AlternateSectionStyled>
      </Page>
    </WithAuth>
  );
};

const AlternateSectionStyled = styled(AlternateSection)`
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1]} 0;
  }
`;

const HeaderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0 ${theme.spacing[2]};
  }
`;

const SectionStyled = styled.div`
  margin-top: ${theme.spacing[2]};
`;
