import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import styled from "styled-components";
import { Baskets } from "../../components/Baskets";
import { Button } from "../../components/Button";
import { Epicerie } from "../../components/Epicerie";
import { Heading } from "../../components/Heading";
import { Hero } from "../../components/Hero";
import { Map } from "../../components/Map";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { Stack } from "../../components/Stack";
import { Testimonials } from "../../components/Testimonials";
import { Text } from "../../components/Text";
import { theme } from "../../components/theme";
import { useGetAllPup } from "../../hooks/Delivery";
import { useUser } from "../../hooks/Users";
import { AdvantagesSecondlife } from "../../components/AdvantagesSecondlife";
import HowItWorks from "../../components/HowItWorks";
import { TTopicEnum } from "../../types/Faq";
import { HelpList } from "../../components/HelpList";

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const { data: locations = [] } = useGetAllPup();
  const { data: user } = useUser();
  const [lat, setLat] = useState<number>(46.183871);
  const [lng, setLng] = useState<number>(-72.867557);
  const [zoom, setZoom] = useState<number>(8);

  const setAddress = async (placeTerms: any) => {
    if (!placeTerms) return;

    const newAddress = {
      streetNumber: "",
      address: "",
      city: "",
      province: "",
      zip_code: "",
      country: "",
    };

    placeTerms.forEach((term: any) => {
      if (term.types.includes("street_number"))
        newAddress["streetNumber"] = term.long_name;
      if (term.types.includes("route")) newAddress["address"] = term.long_name;
      if (term.types.includes("locality")) newAddress["city"] = term.long_name;
      if (term.types.includes("administrative_area_level_1"))
        newAddress["province"] = term.short_name;
      if (term.types.includes("postal_code"))
        newAddress["zip_code"] = term.long_name;
      if (term.types.includes("country"))
        newAddress["country"] = term.short_name;
    });

    let addressfinal = `${newAddress.streetNumber} ${newAddress.address}`;
    if (addressfinal === " ") {
      addressfinal = "";
    }
  };
  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      {/* <FlashBanner times={10} type="warning">
        {t("Announcement")}
      </FlashBanner> */}
      <SectionAlignedStyled>
        <Hero
          image="/common/images/hero-1"
          imageAlt="Vegetables basket"
          title={t("Home.Hero.heading")}
          subtitle={t("Home.Hero.subtitle")}
          text={t("Home.Hero.content")}
          wide={true}
          centerWhenSmall={true}
          imageOnTopWhenSmall={true}
        >
          <Stack>
            {user ? (
              <Button to="/cart">{t("Home.Hero.action3")}</Button>
            ) : (
              <Button to="/sign-up/step-1">{t("Home.Hero.action")}</Button>
            )}
            <Button to="/products" thirdReverse>
              {t("Home.Hero.action2")}
            </Button>
          </Stack>
        </Hero>
      </SectionAlignedStyled>

      <SectionStyled className="mt-5">
        <HeadingSecondary center>
          {nl2br(t("HowItWorksTitle"))}
        </HeadingSecondary>
        <HowItWorks />
      </SectionStyled>

      <SectionStyled>
        <HeadingPrimary center>{nl2br(t("AdvantageTitle"))}</HeadingPrimary>
        <AdvantagesSecondlife />
      </SectionStyled>

      <SectionAlignedStyled className={"produces_saved_reasons"}>
        <Heading center element="h2" size="xl">
          {nl2br(t("WhyProducesSaved"))}
        </Heading>
        <HeadingPrimary center>
          {nl2br(t("HereAreSomeExamples"))}
        </HeadingPrimary>
        <img
          width="100%"
          src={`${nl2br(t("WhyProducesSavedImage"))}`}
          alt="Reasons why produces are thrown away"
        ></img>
        <img
          width="100%"
          src={`${nl2br(t("WhyProducesSavedImage2"))}`}
          alt="Reasons 2 why produces are thrown away"
        ></img>
      </SectionAlignedStyled>

      <SectionAlignedStyled className="mt-5">
        <Epicerie />
      </SectionAlignedStyled>

      <PointDeCuillete>
        <div className="info">
          <Heading element="h2" size="xl">
            {t("Home.Header.PUPInMontreal")}
          </Heading>
          <Text style={{ textAlign: "left" }}>
            {t("Home.content1.PUPInMontreal")}
          </Text>
          {/* <TextTopMargin style={{ textAlign: "left" }}>
            {t("Home.content2.PUPInMontreal")}
          </TextTopMargin> */}
          <ButtonTopMargin thirdReverse to="/pick-up-points">
            {t("Home.FindPuPNearYou")}
          </ButtonTopMargin>
        </div>
        <div className="map">
          <Map
            center={{ lat, lng }}
            zoom={zoom}
            markers={locations}
            currentPosition={{ lat, lng }}
            onSelectedPup={() => {
              return;
            }}
          />
        </div>
      </PointDeCuillete>

      <Testimonials />
      <Heading center element="h2" size="xl">
        {nl2br(t("Home.faq.title"))}
      </Heading>
      <HelpList topic={TTopicEnum.MAIN} />
    </Page>
  );
};
const TextStyled = styled(Text)`
  color: ${theme.colors.base.secondary};
`;

const TextWrapper = styled.div`
  padding-bottom: ${theme.spacing[2]};
  text-align: center;

  color: ${theme.colors.base.secondary};
`;

const SectionStyled = styled.section`
  & {
    padding: ${theme.spacing[3]} 0;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    & {
      padding-top: 0px;
    }
  }

  &.produces_saved_reasons {
    padding: 0 !important;
  }
`;

const SectionAlignedStyled = styled(Section)`
  & {
    padding: ${theme.spacing[3]} ${theme.spacing[2]};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    & {
      padding-top: 0px;
    }
  }

  &.produces_saved_reasons {
    padding: 0 !important;
  }
`;

const HeadingPrimary = styled(Heading)`
  color: ${theme.colors.base.primary};
  margin-bottom: ${theme.spacing[2]};
`;

const HeadingSecondary = styled(Heading)`
  color: ${theme.colors.base.secondary};
  margin-bottom: ${theme.spacing[2]};
  font-size: 33px;
`;

const TextTopMargin = styled(Text)`
  margin-top: ${theme.spacing[1]};
`;

const ButtonTopMargin = styled(Button)`
  margin-top: ${theme.spacing[1.5]};
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const PointDeCuillete = styled.div`
  display: flex;
  width: 100%;
  background-color: #ffffff;

  .info {
    flex-grow: 1;
    width: 50%;
    padding: 5.5rem;
  }

  .map {
    flex-grow: 1;
    width: 50%;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;

    .info {
      flex-grow: 1;
      width: 100%;
      padding: 1.75rem;
      margin-top: 10px;
      text-align: center;
    }

    .map {
      flex-grow: 1;
      width: 100%;
      height: 400px;
    }
  }
`;
