import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { Label } from "../../components/Label";
import { Page } from "../../components/Page";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useAppState } from "../../contexts";
import { useOrder } from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { Navigation } from "../Navigation";
import { Cell } from "./Cell";
import config from "../../config";
import { formatCurrency, formatDate } from "../../utils/i18n";

export const SpecificOrder: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const history = useHistory();
  const { data: subscription } = useSubscription();
  const { oid } = useParams<{ oid: string }>();
  const { data: order } = useOrder(oid);

  useEffect(() => {
    if (subscription && subscription?.plans?.length === 0) {
      return history.push("/sign-up/step-2");
    }

    if (subscription && !subscription.lastDigits) {
      return history.push("/sign-up/confirm");
    }

    if (subscription && subscription.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        <AlternateSection>
          <Navigation />
          <Card>
            <Header>
              <HeaderStyled>
                <BaseLabel size="lg">
                  <strong>{t("History.invoice")} </strong>
                  {order?.oid?.slice(0, 10)}
                </BaseLabel>
                {order?.delivery_date && (
                  <BaseLabel size="lg">
                    <strong> {t("History.delivery-date")}</strong>{" "}
                    {formatDate(locale, new Date(order?.delivery_date))}
                  </BaseLabel>
                )}
                {order?.entity_type && (
                  <BaseLabel size="lg">
                    <strong> {t("History.type")} </strong>{" "}
                    {t(`Dashboard.delivery.type.${order?.entity_type}`)}
                  </BaseLabel>
                )}
                {order?.address && (
                  <BaseLabel size="lg">
                    <strong> {t("History.address")} </strong> {order?.address}
                  </BaseLabel>
                )}
                {order?.where && (
                  <BaseLabel size="lg">
                    <strong> {t("History.name")} </strong> {order?.where}
                  </BaseLabel>
                )}

                <BaseLabel bold size="lg">
                  <strong> {t("History.payment-mode")} </strong>{" "}
                  {subscription?.brand.toUpperCase()}
                  ***{subscription?.lastDigits}
                </BaseLabel>
                <BaseLabel bold size="lg">
                  <strong> {t("History.certification")} </strong>{" "}
                  {t("History.ecocert")}
                </BaseLabel>
              </HeaderStyled>
              <ImgStyled
                src={`${config.CDN_URL}/common/images/logo1.png`}
                alt={t(`Footer.info.logo-alt`)}
                loading="lazy"
              />
            </Header>
            <SeparatorBold />

            <BodyStyled>
              {order?.products?.map((product, index) => (
                <Cell key={index} product={product} />
              ))}
            </BodyStyled>

            <FooterStyled>
              {order && (
                <CartWrapper>
                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.subtotal")} :</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {formatCurrency(locale, order?.subtotal_price / 100)}
                    </BaseBoldLabel>
                  </RowWrapper>
                  <RowWrapper>
                    <BaseLabel size="lg">
                      {t("Signup.confirm.delivery")} :
                    </BaseLabel>
                    <BaseBoldLabel size="lg">
                      {formatCurrency(locale, order?.shipping_price / 100)}
                    </BaseBoldLabel>
                  </RowWrapper>
                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.tips")} :</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {formatCurrency(locale, order.tips_price / 100)}
                    </BaseBoldLabel>
                  </RowWrapper>
                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.tax.tps")} :</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {formatCurrency(locale, order.price_tax_1 / 100)}
                    </BaseBoldLabel>
                  </RowWrapper>
                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.tax.tvq")} :</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {formatCurrency(locale, order.price_tax_2 / 100)}
                    </BaseBoldLabel>
                  </RowWrapper>
                  {order?.discount && (
                    <RowWrapper>
                      <BaseLabel size="lg">
                        {t("Cart.discount-admin")} :
                      </BaseLabel>
                      <BaseBoldLabel size="lg">{order?.discount}</BaseBoldLabel>
                    </RowWrapper>
                  )}
                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.credit-admin")} :</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {order?.credit
                        ? formatCurrency(locale, order.credit / 100)
                        : formatCurrency(locale, 0)}
                    </BaseBoldLabel>
                  </RowWrapper>

                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.credit-stripe")} :</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {order?.credit_stripe
                        ? formatCurrency(locale, order?.credit_stripe / 100)
                        : formatCurrency(locale, 0)}
                    </BaseBoldLabel>
                  </RowWrapper>
                  {order.coupon_stripe > 0 && (
                    <RowWrapper>
                      <BaseLabel size="lg">
                        {t("Cart.coupon-stripe")} :
                      </BaseLabel>
                      <BaseBoldLabel size="lg">
                        -{formatCurrency(locale, order.coupon_stripe / 100)}
                      </BaseBoldLabel>
                    </RowWrapper>
                  )}
                  <Separator />
                  <RowWrapper>
                    <BaseLabel size="lg">{t("Cart.total")}</BaseLabel>
                    <BaseBoldLabel size="lg">
                      {formatCurrency(locale, order.total_price / 100)}
                    </BaseBoldLabel>
                  </RowWrapper>
                </CartWrapper>
              )}
            </FooterStyled>
          </Card>
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};
const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${theme.colors.base.secondary};
`;
const SeparatorBold = styled.div`
  width: 100%;
  height: 3px;
  background: ${theme.colors.base.secondary};
`;

const ImgStyled = styled.img`
  display: flex;
  max-width: 40%;
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: 20%;
    padding: ${theme.spacing[2]};
  }
`;

const HeaderStyled = styled.div`
  padding-top: ${theme.spacing[3]};
  @media (min-width: ${theme.breakpoints.sm}) {
    padding-top: ${theme.spacing[6]};
  }
  padding-bottom: ${theme.spacing[2]};
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1.5]};
  padding: ${theme.spacing[2]} ${theme.spacing[1.5]};
  background: ${theme.colors.base.white};
  border: 2px solid ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const BodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: row;
  }
`;
const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing[1]};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.accent.secondary};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseLabel = styled(Label)`
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const BaseBoldLabel = styled(BaseLabel)`
  font-weight: ${theme.font.weight.bold};
  padding-right: ${theme.spacing[2]};
`;

const CartWrapper = styled(ColumnWrapper)`
  margin-top: ${theme.spacing[0.5]};
`;
