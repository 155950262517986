import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { useAppState } from "../../contexts";
import { useCategoriesTree } from "../../hooks/Categories";
import { SpecialCategories, TCategory } from "../../types/Category";
import usePrevious from "../../utils/hooks";
import { Label } from "../Label";
import { SearchAlgolia } from "../SearchAlgolia";
import { spacing, theme } from "../theme";
import { CategoryMemorized as Category } from "./Category";
import { Dropdown } from "../Dropdown";

const FrontElement = {
  TREE: "tree",
  SEARCH: "search",
};

interface Props {
  selectedCategory?: string;
  searchQuery: string;
  showSearch: boolean;
  isConnectedUser: boolean;
  orderByFilter: string;
  onOrderBySelected: (params: string) => void;
}

const Filter: React.FC<Props> = ({
  selectedCategory,
  searchQuery,
  showSearch,
  isConnectedUser,
  orderByFilter,
  onOrderBySelected,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useAppState();
  const { search } = useLocation();
  const [lang] = locale.split("-");
  const { data: tree = [] } = useCategoriesTree();
  const [frontElement, setFrontElement] = useState(FrontElement.TREE);
  const [flatTree, setFlatTree] = useState<TCategory[]>();
  const isTree = localStorage.getItem("tree");
  const [showTree, setShowTree] = useState(Boolean(isTree === "true"));
  const previousTree = usePrevious(tree);

  useEffect(() => {
    if (isTree) {
      setShowTree(Boolean(isTree === "true"));
    }
  }, [isTree]);

  useEffect(() => {
    if (JSON.stringify(tree) !== JSON.stringify(previousTree)) {
      const flattenTree = tree
        .map((cat) => {
          const { subcategories, ...catProps } = cat;
          return [...(subcategories ? subcategories : []), catProps];
        })
        .reduce((acc, val) => [...acc, ...val], []);
      setFlatTree(flattenTree);
    }
  }, [tree]);

  function updateTreeLocalStorage() {
    const isTree = localStorage.getItem("tree");
    const booleanValue = Boolean(isTree === "true");
    const invertedValue = !booleanValue;
    localStorage.setItem("tree", invertedValue ? "true" : "");
  }
  const toggleTree = () => {
    updateTreeLocalStorage();
    setShowTree(!showTree);

    frontElement === FrontElement.SEARCH && setFrontElement(FrontElement.TREE);
  };

  const onClickSearch = () => {
    frontElement === FrontElement.TREE && setFrontElement(FrontElement.SEARCH);
  };

  const onSelectCategory = (category: TCategory) => {
    if (!isConnectedUser) {
      return history.push({ pathname: "/dashboard" });
    }
    const params = new URLSearchParams(search);
    const currentOrder = new URLSearchParams().get("order_by");
    if (currentOrder) params.set("order_by", currentOrder);
    params.set("category", category.cid);
    updateTreeLocalStorage();
    setShowTree(!showTree);
    history.push({ pathname: "/products", search: params.toString() });
  };

  const onSelectSpecialCategory = (specialCategory: string) => {
    if (!isConnectedUser) {
      return history.push({ pathname: "/dashboard" });
    }
    const params = new URLSearchParams(search);
    const currentOrder = new URLSearchParams().get("order_by");
    if (currentOrder) params.set("order_by", currentOrder);
    updateTreeLocalStorage();
    params.set("category", specialCategory);
    history.push({ pathname: "/products", search: params.toString() });
  };

  const firstColumCategories = tree.slice(0, Math.min(2, tree.length));
  const treeColumns = tree.length > 2 ? tree.slice(2) : [];

  let title = "";
  switch (selectedCategory) {
    case SpecialCategories.PROMO:
      title = t("Catalog.promos");
      break;
    case SpecialCategories.LOCALS:
      title = t("Catalog.locals");
      break;
    case SpecialCategories.SAVED:
      title = t("Catalog.saved");
      break;
    case SpecialCategories.NEW:
      title = t("Catalog.new");
      break;
    default:
      const categoryTitle = flatTree?.find(
        (cat) => cat.cid === selectedCategory
      )?.copy[lang]?.name;
      if (categoryTitle) {
        title = categoryTitle;
      }
      break;
  }
  if (searchQuery) {
    title = `${t("Catalog.search.results")} ${searchQuery}`;
  }

  return (
    <FilterContainer unfolded={showTree}>
      <FilterWrapper unfolded={showTree}>
        <PermanentWrapper>
          <FilterTitle size="xl" weight="black">
            {showTree ? t("Catalog.alleys") : title}
          </FilterTitle>
          <ActionsWrapper>
            <ToggleButton
              onClick={toggleTree}
              hideState={showTree}
              reduced={false}
            >
              <StyledLabel
                size="mdx"
                weight="bold"
                hideState={showTree}
                reduced={false}
              >
                {t(showTree ? "Catalog.hide.alleys" : "Catalog.show.alleys")}
              </StyledLabel>
              {showTree ? <icons.close /> : <icons.plainChevronDown />}
            </ToggleButton>
            {showSearch && isConnectedUser && (
              <SearchWrapper>
                <SearchAlgolia
                  onSendRequest={(done) => {
                    if (done) setShowTree(false);
                  }}
                  onClick={onClickSearch}
                  showFull={frontElement === FrontElement.SEARCH}
                />
              </SearchWrapper>
            )}
            <div style={{ width: "fit-content" }}>
              <Dropdown
                name="Filtre"
                preLabel={t("Catalog.filter.preLabel")}
                options={[
                  {
                    label: "Promotions",
                    value: "promo",
                    selected: orderByFilter === "promo",
                  },
                  {
                    label: t("Catalog.filter.productName"),
                    value: "name",
                    selected: orderByFilter === "name",
                  },
                ]}
                onSelect={(value) => {
                  onOrderBySelected(value.value);
                  setShowTree(false);
                }}
              />
            </div>
          </ActionsWrapper>
        </PermanentWrapper>
        <TreeWrapper>
          <FirstColumnWrapper>
            {firstColumCategories && firstColumCategories.length > 0 && (
              <>
                {firstColumCategories.map((cat) => (
                  <Category
                    key={cat.cid}
                    category={cat}
                    subcategories={cat.subcategories}
                    onSelectCategory={onSelectCategory}
                  />
                ))}
              </>
            )}
            <SpecialCategoriesWrapper>
              <SpecialCategory
                onClick={() => onSelectSpecialCategory(SpecialCategories.PROMO)}
              >
                <PromoTag
                  bgColor={theme.colors.base.primary}
                  hoverColor={theme.colors.base.primary}
                  selected={selectedCategory === "is_promo"}
                  key="local"
                >
                  {t("Catalog.promos")}
                </PromoTag>
              </SpecialCategory>
              <SpecialCategory
                onClick={() =>
                  onSelectSpecialCategory(SpecialCategories.LOCALS)
                }
              >
                <PromoTag
                  bgColor={"#003da5"}
                  hoverColor={"#003da5"}
                  selected={selectedCategory === "is_local"}
                  key="local"
                >
                  {t("Catalog.locals")}
                </PromoTag>
              </SpecialCategory>
              <SpecialCategory
                onClick={() => onSelectSpecialCategory(SpecialCategories.SAVED)}
              >
                <PromoTag
                  bgColor={theme.colors.base.secondary}
                  hoverColor={theme.colors.base.secondary}
                  selected={selectedCategory === "is_saved"}
                  key="saved"
                >
                  {t("Catalog.saved")}
                </PromoTag>
              </SpecialCategory>
              <SpecialCategory
                onClick={() => onSelectSpecialCategory(SpecialCategories.NEW)}
              >
                <PromoTag
                  bgColor={theme.colors.base.third}
                  hoverColor={theme.colors.base.third}
                  selected={selectedCategory === "is_featured"}
                  key="new"
                >
                  {t("Catalog.new")}
                </PromoTag>
              </SpecialCategory>
            </SpecialCategoriesWrapper>
          </FirstColumnWrapper>
          <ColumnWrapper>
            {treeColumns && treeColumns.length > 0 && (
              <>
                {treeColumns.map((element) => {
                  return (
                    <Category
                      key={element.cid}
                      category={element}
                      subcategories={element.subcategories}
                      onSelectCategory={onSelectCategory}
                    />
                  );
                })}
              </>
            )}
          </ColumnWrapper>
        </TreeWrapper>
      </FilterWrapper>
    </FilterContainer>
  );
};

export const FilterMemorized = React.memo(Filter);

const FilterContainer = styled.div<{ unfolded: boolean }>`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: row;
  justify-content: center;
  transition: background ${theme.transition.fast};

  @media (min-width: ${theme.breakpoints.lg}) {
    padding: 2rem;
  }

  ${(props) =>
    props.unfolded &&
    `
    background: ${theme.colors.base.white};
  `}
`;

const FilterWrapper = styled.div<{ unfolded: boolean }>`
  width: 100%;
  max-width: 1086px;
  max-height: 155px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height ${theme.transition.fast};

  ${(props) =>
    props.unfolded &&
    `
    max-height: 9000px;
  `}

  @media (min-width: ${theme.breakpoints.sm}) {
    max-height: 75px;

    ${(props) =>
      props.unfolded &&
      `
      max-height: 580px;
      background: ${theme.colors.base.white};
    `}
  }
`;

const PermanentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const FilterTitle = styled(Label)`
  flex: 1;
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.xxl};
  color: ${theme.colors.base.secondary};
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: ${theme.spacing[0.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const ToggleButton = styled.div<{ hideState: boolean; reduced: boolean }>`
  width: 266px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(0.75)} ${spacing(1.25)};
  background: ${theme.colors.accent.secondary};
  border: 1px solid ${theme.colors.base.white};
  box-sizing: border-box;
  border-radius: 83px;
  cursor: pointer;
  transition: background ${theme.transition.fast};

  ${(props) =>
    props.hideState &&
    `
  background: ${theme.colors.base.secondary};

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${theme.colors.base.white};
    }
  }
  `}

  @media (max-width: ${theme.breakpoints.sm}) {
    transition: max-width ${theme.transition.fast};
    width: 100%;
    ${(props) =>
      props.reduced &&
      `
      max-width: 48px;
    `}
  }
`;

const SearchWrapper = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    width: 225px;
  }
`;

const StyledLabel = styled(Label)<{ hideState: boolean; reduced: boolean }>`
  margin-right: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
  transition: color ${theme.transition.fast};

  ${(props) =>
    props.hideState &&
    `
  color: ${theme.colors.base.white};
  `}

  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 500px;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width ${theme.transition.fast};

    ${(props) =>
      props.reduced &&
      `
      max-width: 0;
      margin-right: 0;
    `}
  }
`;

const TreeWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1]};
  overflow: hidden;

  @media (min-width: ${theme.breakpoints.sm}) {
    height: 396px;
    flex-direction: row;
  }
`;

const ColumnWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  @media (min-width: ${theme.breakpoints.sm}) {
    height: 396px;
    flex-wrap: wrap;
  }
`;

const FirstColumnWrapper = styled(ColumnWrapper)`
  flex-direction: column-reverse;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    margin-right: ${theme.spacing[3]};
  }
`;

const SpecialCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${theme.spacing[2]};
  column-gap: 0.25rem;
  max-width: 25rem;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

const SpecialCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;

  span:hover {
    background: none;
  }
`;

const PromoTag = styled.span<{
  bgColor: string;
  hoverColor: string;
  selected: boolean;
}>`
  align-self: flex-start;
  margin: 2px;
  margin-left: 0;
  padding: 8px 16px;
  white-space: nowrap;
  border-radius: 64px;
  border: 1px solid ${(props) => props.bgColor};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.md};
  line-height: ${theme.font.size.md};
  width: fit-content;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  ${(props) =>
    props.selected
      ? `background: transparent;
    color: ${props.hoverColor};
    `
      : `background: ${props.bgColor};
     color: ${theme.colors.base.white};
     `};

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.colors.accent.secondary};
`;

const CategoryLabel = styled(Label)`
  flex: 1;
  margin: 0 ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
  white-space: nowrap;
`;
